import { each, map, find } from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import createPopup from '../popup-factory';
import { getPurchaseOrderProofsList } from '../selectors';
import { getImageSrc, getDownloadSrc } from '../utils';
import { createCommentPopup } from '../actions/popup';
import { updateProofStatus } from '../actions/purchase_order_proof';
import ProofStatus from '../models/ProofStatus';
import ProofComment from '../components/ProofComment';

class ProofApproval extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleUpdateStatus(proof, title, status_id) {
    const { identity, createCommentPopup, updateProofStatus } = this.props;
    const { commenter_name, commenter_email } = this.state;
    const isPublic = !identity;
    return createCommentPopup({
      title,
      commenter_name,
      commenter_email,
      onSubmit: (comment) => {
        this.setState({
          commenter_name: comment.commenter_name,
          commenter_email: comment.commenter_email,
        });

        const options = {
          ...comment,
          isPublic,
        };
        if (!!identity) {
          options.commenter_name = `${identity.user_first_name} ${identity.user_last_name}`;
        }
        return updateProofStatus(proof, status_id, options);
      }
    });
  }

  renderComments(comments) {
    return map(comments, (comment) => <ProofComment key={comment.comment_id} comment={comment}/>);
  }

  renderProof(proof) {
    const { tenant } = this.props;
    const {
      file, purchase_order_proof_id, status_id, comments
    } = proof;
    const { file_display_name, is_image } = file || {};

    return <div key={purchase_order_proof_id} className="row proof-row" style={{ padding: 20 }}>
      <div className="small-6 medium-2 columns" style={{ textAlign: 'center' }}>
        <a href={getDownloadSrc(file, tenant.tenant_id)} target="_blank" rel="noreferrer">
          <div style={{
            background: `url(${is_image ? getImageSrc(file) : '/images/404.png'}) no-repeat center`,
            backgroundSize: 'contain',
            width: 120,
            height: 120,
            margin: '0 auto',
            display: 'inline-block',
          }}/>
        </a>
      </div>
      <div className="small-6 medium-4 columns">
        <h4 style={{ display: 'block', fontWeight: 'bold', fontSize: 14 }}>{ file_display_name }</h4>
        <a className="button small" target="_blank" rel="noreferrer" style={{ display: 'inline-block' }}
          href={getDownloadSrc(file, tenant.tenant_id)}
        >Download</a>
      </div>
      <div className="small-12 medium-6 columns">
        {this.isApproved(status_id) ? null : <div className="row full-width">
          <div className="small-6 columns" style={{ paddingLeft: 0 }}>
            <button className="button small request-change" style={{width: '100%'}} onClick={() => {
              this.handleUpdateStatus(proof, 'Change Required', ProofStatus.ChangeRequested);
            }}>Request Change</button>
          </div>
          <div className="small-6 columns" style={{ paddingRight: 0 }}>
            <button className="button small success approve" style={{width: '100%'}} onClick={() => {
              this.handleUpdateStatus(proof, 'Approve', ProofStatus.Approved);
            }}>Approve</button>
          </div>
        </div>}
        {this.renderComments(comments)}
      </div>
    </div>;
  }

  isApproved(status_id) {
    return [ProofStatus.Approved, ProofStatus.ProofingComplete].indexOf(status_id) > -1;
  }

  render() {
    const {
      popups, purchase_order_proofs
    } = this.props;

    const approved = [];
    const unapproved = [];

    each(purchase_order_proofs, (proof) => {
      if (this.isApproved(proof.status.status_id)) {
        approved.push(proof);
      } else {
        unapproved.push(proof);
      }
    });

    return <div className="columns small-12 proof-approval-body">
      {unapproved.length === 0 ? null : <div className="white" style={{ 'padding': '25px 20px' }}>
        <h4 className="small-12 columns">To be approved</h4>
        {map(unapproved, (proof) => this.renderProof(proof))}
      </div>}

      {approved.length === 0 ? null : <div className="white" style={{ 'padding': '25px 20px' }}>
        <h4 className="small-12 columns">Approved proofs</h4>
        {map(approved, (proof) => this.renderProof(proof))}
      </div>}

      {popups.map((p, idx) => createPopup(p, idx))}
      {!popups.length ? null :
          <div className="reveal-overlay" tabIndex="-1" aria-hidden="true"
            style={{display: 'block', overflow: 'hidden'}}></div>}
    </div>;
  }
}

const mapStateToProps = (state, ownProps) => {
  const { accounts, projects, tenants, statuses } = state.entities || {};
  return {
    identity: state.identity,
    popups: state.display.popups,
    project: find(projects),
    tenant: find(tenants),
    account: find(accounts),
    statuses,
    purchase_order_proofs: getPurchaseOrderProofsList(state),
  };
};

export default connect(mapStateToProps, {
  createCommentPopup, updateProofStatus
})(ProofApproval);

